/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.exposed-collection-aggregations .search {
  display: flex;
  padding: 20px 20px 10px 20px;
  align-items: center;
}
.exposed-collection-aggregations .search .query {
  flex: 1;
  padding: 7px;
  border: 0;
  background-color: #fEFEFE;
  border-left: 4px solid #efefef;
}
.exposed-collection-aggregations .search .add {
  outline: none;
  margin-left: 10px;
  border-radius: 50%;
  border: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: #0068FF;
  border: 2px solid #0068ff;
}
.exposed-collection-aggregations .header-filter-collection {
  padding: 10px 20px 10px 20px;
  display: flex;
  flex: 1;
}
.exposed-collection-aggregations .header-filter-collection .header-filter-section {
  display: flex;
  flex: 1;
}
.exposed-collection-aggregations .header-filter-collection .header-filter-section .header-filter-item {
  display: flex;
  flex: 1;
}
.exposed-collection-aggregations .tag-pill--small {
  background-color: #f3f3f3;
  padding: 0px 10px;
  border-radius: 6px;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  letter-spacing: 1px;
  margin-right: 10px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 3px;
  white-space: nowrap;
}
.exposed-collection-aggregations .table-borderless tbody tr td,
.exposed-collection-aggregations .table-borderless tbody tr th,
.exposed-collection-aggregations .table-borderless thead tr th {
  border: none;
}
.exposed-collection-aggregations .filter tr {
  background: none !important;
}
.exposed-collection-aggregations .altai-tooltip .altai-tooltip-text {
  white-space: nowrap;
  padding: 5px;
  background-color: #f3f3f3;
  color: #000;
}
.exposed-collection-aggregations .btn-search-filter {
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  border: 0px solid #c6c6c6;
  margin: 0px 10px 0px 10px;
  font-size: 14px;
  border-radius: 0px;
  color: #555;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  white-space: normal;
  background-color: transparent;
  font-weight: 600;
}
.exposed-collection-aggregations .btn-search-filter:hover {
  color: #505050 !important;
  cursor: pointer;
}
.exposed-collection-aggregations .select {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  display: inline-flex;
  font-weight: 700;
  font-size: 0.8em;
  border: none;
  margin: 0;
  white-space: nowrap;
  padding: 10px 0px;
  width: 50%;
  background: #F3F3F3;
}
.exposed-collection-aggregations .selectcontainer {
  background: #F3F3F3;
}
.exposed-collection-aggregations .select:hover {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #505050;
  color: #FFF;
  cursor: pointer;
}
.exposed-collection-aggregations .select-left {
  margin-right: -6px;
  padding-left: 4px;
}
.exposed-collection-aggregations .select-right {
  margin-right: -5px;
  padding-left: 4px;
  margin-left: -5px;
  text-align: right;
}
.exposed-collection-aggregations .btn-search-filter.disabled {
  font-weight: 400;
  color: gray;
}
.exposed-collection-aggregations .btn-search-filter.disabled:hover {
  color: gray !important;
  cursor: default;
}
.exposed-collection-aggregations .btn-search-filter i {
  display: inline-block;
  vertical-align: middle;
  font-size: 2em;
  line-height: 1em;
}
.exposed-collection-aggregations ul.scroll {
  margin: 0;
  padding: 0;
  min-width: 250px;
  max-height: 500px;
  /* you can change as you need it */
  overflow-y: auto;
  /* to get scroll */
  overflow-x: hidden;
  box-sizing: border-box;
  display: block;
  list-style-type: disc;
}
.exposed-collection-aggregations ul.dropdown-menu {
  margin: 0;
  padding: 0;
}
.exposed-collection-aggregations .list-group-item {
  width: 100%;
  min-width: 100%;
  white-space: nowrap;
  display: inline-block;
  border: none;
  position: relative;
  padding: 10px 0px 10px 5px;
  background-color: #fff;
  margin-right: 35px;
}
.exposed-collection-aggregations .list-group-item .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px;
  margin-right: 10px;
}
.exposed-collection-aggregations .list-group-item:hover {
  background: #505050;
  border-color: #505050;
  color: #FFF;
  cursor: pointer;
}
.exposed-collection-aggregations .list-group-item:hover .badge {
  background: #FFF;
  color: #505050;
}
.exposed-collection-aggregations .list-group-item.active {
  background: #505050 !important;
  border-color: #505050 !important;
  font-weight: bold;
  color: #FFF;
}
.exposed-collection-aggregations .list-group-item.active .badge {
  margin-top: 0.6em;
  background: #FFF;
  color: #505050;
}
.exposed-collection-aggregations .list-group-item.child {
  font-size: 80%;
  text-indent: 2em;
}
.exposed-collection-aggregations .list-group-item.child .badge {
  text-indent: 0;
}
.exposed-collection-aggregations .search-bar {
  background: #F3F3F3 !important;
  color: #252525;
  font-weight: bold;
  margin: 5px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.exposed-collection-aggregations input[type="text"].search-bar {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: black;
}
.exposed-collection-aggregations input[type="text"].search-bar::-webkit-input-placeholder {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.exposed-collection-aggregations input[type="text"].search-ba::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.exposed-collection-aggregations input[type="text"].search-ba::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.exposed-collection-aggregations input[type="text"].search-ba:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.exposed-collection-aggregations input[type="text"].search-ba:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.exposed-collection-aggregations .daterange table {
  padding: 20px 0 20px 0;
  margin: 0!important;
  padding: 0!important;
}
.exposed-collection-aggregations .daterange table tr td {
  padding: 0!important;
}
.exposed-collection-aggregations .daterange .rangebutton {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  height: 25px;
  background: none;
  color: #767676;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  margin: 2px 5px 2px 5px;
  vertical-align: middle;
  cursor: pointer;
}
.exposed-collection-aggregations .daterange .rangebutton:hover {
  font-weight: 800;
}
.exposed-collection-aggregations .daterange .range {
  display: inline-block;
  text-align: center;
  margin: 5px 5px 5px 5px;
  width: 100%;
  background: #FFF;
  border-color: #FFF;
  color: #767676;
  overflow: visible;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
}
.exposed-collection-aggregations .daterange .range:hover {
  font-weight: 800;
}
.exposed-collection-aggregations .daterange .su-datepicker {
  background-color: #FFF;
  font-size: 90%;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  margin-left: 0px;
  padding: 10px 20px 10px 20px;
  position: relative;
  display: inline-flex;
}
.exposed-collection-aggregations .daterange .su-datepicker-default,
.exposed-collection-aggregations .daterange .su-datepicker-range {
  width: 375px;
}
.exposed-collection-aggregations .daterange .su-datepicker-range > div:nth-of-type(1) {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .exposed-collection-aggregations .daterange .su-datepicker-range > div {
    float: left;
    width: 50%;
  }
  .exposed-collection-aggregations .daterange .su-datepicker-range > div:nth-of-type(1) {
    margin-bottom: 0;
  }
  .exposed-collection-aggregations .daterange .su-datepicker-range > div:nth-of-type(2) {
    padding-left: 10px;
  }
  .exposed-collection-aggregations .daterange .su-datepicker-range:after {
    display: table;
    clear: both;
    content: " ";
  }
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-header,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-header {
  color: #767676;
  font-size: 100%;
  padding-bottom: 5px;
  text-align: center;
}
.exposed-collection-aggregations .daterange .su-datepicker-default table,
.exposed-collection-aggregations .daterange .su-datepicker-range table {
  border-collapse: collapse;
  border-spacing: 0;
}
.exposed-collection-aggregations .daterange .su-datepicker button {
  border: 0;
  margin: 0;
  padding: 0;
  height: inherit;
  color: #000;
}
.exposed-collection-aggregations .daterange .su-datepicker-default table th,
.exposed-collection-aggregations .daterange .su-datepicker-range table th {
  margin: 0;
  padding: 0 ;
  color: #767676;
  background: none!important;
  font-size: 85%;
}
.exposed-collection-aggregations .daterange .su-datepicker-default td,
.exposed-collection-aggregations .daterange .su-datepicker-range td {
  padding: 0;
  margin: 0;
  background: #F2F2F2;
}
.exposed-collection-aggregations .daterange .su-datepicker-day {
  background-color: #F3F3F3;
  border-color: transparent;
  border-style: inset;
  cursor: pointer;
  font-size: inherit!important;
  font-weight: normal;
  padding: 9px;
  transition: all 0.125s ease-in-out;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-day:active,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-day:focus,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-day:active,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-day:focus,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button:active,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button:focus,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button:active,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button:focus {
  outline: none;
}
.exposed-collection-aggregations .daterange .su-datepicker-default button[disabled],
.exposed-collection-aggregations .daterange .su-datepicker-range button[disabled] {
  background: #fff;
  color: #999;
  cursor: not-allowed;
  opacity: .65;
  pointer-events: none;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button {
  padding: 0 2px 5px 2px;
  position: absolute;
  top: 40%;
  width: 0;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button-previous,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button-previous {
  left: -28px;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button-next,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button-next {
  right: 8px;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px!important;
  color: #767676;
  font-size: 1.5em;
  height: 35px;
  line-height: 0;
  padding: 5px;
  transition: all 0.125s ease-in-out;
  width: 35px;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button .su-prev-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button .su-prev-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button .su-next-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button .su-next-arrow {
  fill: rgba(0, 0, 0, 0.5);
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button[disabled] .su-prev-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] .su-prev-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button[disabled] .su-next-arrow,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] .su-next-arrow {
  fill: #ddd;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button:hover:enabled,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button:hover:enabled {
  background: #FAFAFA;
  border-color: #767676!important;
  color: #6cb100;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button:active,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button:active,
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button:focus,
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button:focus {
  background: #fff;
  border-color: #767676!important;
  color: #6cb100;
}
.exposed-collection-aggregations .daterange .su-datepicker-default .su-datepicker-change-button button[disabled],
.exposed-collection-aggregations .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] {
  background: #fff;
  border-color: #ddd;
  color: #ddd;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: none;
}
.exposed-collection-aggregations .daterange .su-datepicker-day:hover,
.exposed-collection-aggregations .daterange .potential-range {
  background-color: #767676;
  color: #FFF!important;
}
.exposed-collection-aggregations .daterange .selected-range,
.exposed-collection-aggregations .daterange .start-date,
.exposed-collection-aggregations .daterange .start-date:hover,
.exposed-collection-aggregations .daterange .end-date,
.exposed-collection-aggregations .daterange .end-date:hover {
  background-color: #505050;
  color: #fff!important;
}
.exposed-collection-aggregations .dropup,
.exposed-collection-aggregations .dropdown {
  position: relative;
}
.exposed-collection-aggregations .dropdown-toggle:focus {
  outline: 0;
}
.exposed-collection-aggregations .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.exposed-collection-aggregations .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.exposed-collection-aggregations .dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.exposed-collection-aggregations .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.exposed-collection-aggregations .dropdown-menu > li > a:hover,
.exposed-collection-aggregations .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.exposed-collection-aggregations .dropdown-menu > .active > a,
.exposed-collection-aggregations .dropdown-menu > .active > a:hover,
.exposed-collection-aggregations .dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}
.exposed-collection-aggregations .dropdown-menu > .disabled > a,
.exposed-collection-aggregations .dropdown-menu > .disabled > a:hover,
.exposed-collection-aggregations .dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.exposed-collection-aggregations .dropdown-menu > .disabled > a:hover,
.exposed-collection-aggregations .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed;
}
.exposed-collection-aggregations .open > .dropdown-menu {
  display: block;
}
.exposed-collection-aggregations .open > a {
  outline: 0;
}
.exposed-collection-aggregations .dropdown-menu-right {
  left: auto;
  right: 0;
}
.exposed-collection-aggregations .dropdown-menu-left {
  left: 0;
  right: auto;
}
.exposed-collection-aggregations .dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}
.exposed-collection-aggregations .dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.exposed-collection-aggregations .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.exposed-collection-aggregations .dropup .caret,
.exposed-collection-aggregations .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.exposed-collection-aggregations .dropup .dropdown-menu,
.exposed-collection-aggregations .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
